<template>
  <div class="h-screen" :class="isValidToken ? 'mt-0 p-2' : 'mt-12 p-2'">
     <div class=" bg-white rounded-xl" v-if="!isValidToken">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          <div class=" relative flex items-center justify-between">
            <div>
              Visits ({{totalJobCount}})
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4">
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="visitLIst.length > 0">
            <div v-for="(data, index) in visitLIst" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5">{{data.displayText}}</p>
                </div>
                <div class="flex justify-between items-center">
                  <p class="text-text2 heading-5 py-1 font-semibold whitespace-pre-line">{{data.jobTitle}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div class=" mt-4">
            <Pagination
              :totalDataCount="totalJobCount"
              :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getLeadDataFromPagination"/>
          </div>
        </div>
      </div>
     </div>
     <div v-if="isValidToken" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
          </div>
        </div>
      </div>
     <div v-if="isExpired">
        <EmailVarified />
     </div>
   </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import EmailVarified from '@/View/endUserNewFlow/components/emailVarified.vue'

export default {
  name: "lead",
  components: {
    // Button,
    NoRecords,
    Pagination,
    EmailVarified
  },
  mixins: [deboucneMixin],
  data() {
    return {
      isValidToken: false,
      isExpired: false,
      ShowErr: false,
      sessionToken: '',
      empStatusList: [],
      searchForJob: '',
      visitLIst: [],
      searchForQuotation: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalJobCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        exportData: false,
        sortBy: 'jobNumber',
        sortOrder: 'DESC',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
      showMenuList: false,
      sendEmailReq: false,
    };
  },
  created() {},
  mounted() {
    document.title = 'EndUser: Visit'
    this.sessionToken = this.$route.params.customerToken
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on('tokenResponse', (isTokenExpired, isTokenInvalid) => {
      if (!isTokenExpired && !isTokenInvalid) {
        this.getVisitList()
      } else if (isTokenExpired && !isTokenInvalid) {
        this.isExpired = true
        this.isValidToken = false
        this.firstLoading = true
      } else if (!isTokenExpired && isTokenInvalid) {
        this.isExpired = false
        this.firstLoading = true
        this.isValidToken = true
      }
    })
    this.$root.$emit('tokenVarificationApi', this.$route.params.customerToken)
  },
  beforeDestroy() {
    this.$root.$off('tokenResponse')
  },
  methods: {
  closeBtn () {
    this.sendEmailReq = false
  },
  sendReqBtn () {
    this.sendEmailReq = false
    this.showMenuList = false
    this.filterObject.exportData = true
    this.getVisitList(true)
  },
  closeList () {
    this.showMenuList = false
  },
    showJobDetail (data) {
      this.$router.push({name: 'EndUserVisitDetail', params: {customerToken: this.sessionToken, visitToken: data.visitLinkHash}})
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true)
    },
    getVisitList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetEndUserVisitList(
        this.sessionToken,
        '',
        this.filterObject.start,
        this.filterObject.length,
        '',
        '',
        '',
        response => {
          this.totalJobCount = response.count
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].visitEndDateTime !== '' && temp[index].visitStartDateTime !== '') {
              temp[index].displayText = getDisplayText(temp[index].visitStartDateTime, temp[index].visitEndDateTime)
            } else {
             temp[index].displayText = '--'
            }
          }
          this.visitLIst = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          console.log('error', error)
          if (error.message === 'Your session has expired') {
            this.ShowErr = true
          }
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
</style>